
@mixin bsSpan($max){
    @media #{$md}{
        @for $i from 1 to ($max - 1) {
            .span#{$i} {
                //width: ($max - ($max - $i)) * 10%;
                width: 100 / ($max / $i) * 1%;
            }
        }
    }
}

@include bsSpan(12);


.cck_page{
    @extend .content_limit,.w1200;
    //margin-top: $gutter*2;
    margin-bottom: $gutter*2;
}
.titre-big {
    margin-bottom: $gutter*2;
    font-size: 2rem;
    color: $bleu1;
    @extend .b;
    text-align: center;
    background-color: transparent;
}
.row {
    width: 100%;
}

.row_wrapper{
    display: flex;
    flex-wrap: wrap;
}

.article_item_block {
    margin: $gutter/2;
    font-size: 1.1rem;
    > .block{
        padding: $gutter/2;
        &.titre-block-dark{
            background-color: $bleu1;
            color: $blanc;
            border-color: rgba(255, 255, 255, 0.5);
            box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2),
            0 0 1px rgba(0, 0, 0, 0.2);
        }
        &.titre-block-light{}
        &.box2 {
            color: $blanc;
            background-color: #23b1bf;
            background-image: linear-gradient(#3accdb, #23b1bf 60%, #23b1bf);
            border-top: 1px solid rgba(255, 255, 255, 0.5);
        }
        &.box4 {
            color: $blanc;
            background-color: #336598;
            background-image: linear-gradient(#407ebe, #336598 60%, #336598);
            border-top: 1px solid rgba(255, 255, 255, 0.5);
        }
        &.box6{
            a.titre-seul{
                color: $bleu1;
            }
        }
        .titre {
            font-size: 1.6rem;
            @extend .sb;
            margin-bottom: $gutter/2;
        }
        .titre-seul {
            @extend .b;
            font-size: 1.6rem;
        }
        h2.center{
            @extend .b;
            line-height: 1.4;
            //margin-bottom: $gutter/2;
        }
        strong{
            @extend .b;
            margin-top:$gutter;
            display: block;
        }
        img{
            margin-top:$gutter/2;
            width: 100%;
            height: auto;
            display: block;
        }
        .video-container {
            position: relative;
            padding-bottom: 56.25%;
            padding-top: 30px;
            height: 0;
            overflow: hidden;
            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
}