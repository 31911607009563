#search_question,
#accessoires_search,
#lentilles_search {
    margin-top: $gutter*2;
    margin-bottom: $gutter;
    &.home {
        margin-top: $gutter*2;
        margin-bottom: $gutter*2;
    }

    .filters-button-group {
        text-align: center;
        button{
            background-color: transparent;
            border-width: 3px;
            border-radius: 0.5rem;
            i{
                vertical-align: bottom;
                font-size: 180%;
                line-height: 0.6;
                margin-right: $gutter/4;
            }
            &[data-filter=".item_lentille"]{
                border-color: $bleu1;
                &.is-checked{
                    background-color: $bleu1;
                }
            }
            &[data-filter=".item_accessoire"] {
                border-color: $vert1;
                &.is-checked{
                    background-color: $vert1;
                }
            }
            &.is-checked {
                color: $blanc;
            }
        }
    }

    .search_inner{
        text-align: center;
        width: 100%;
        .search_title {
            padding: $gutter/4 $gutter/2;
            display: inline-block;
            font-size: 1.8rem;
            text-transform: uppercase;
            background-color: $bleu1;
            color: $blanc;
            @extend .sb;
        }
    }
    
    #search_results {
        text-align: center;
        width: 100%;
        font-size: 1.4rem;
        text-transform: uppercase;
        #search_inner {
            .modifier{
                cursor: pointer;
                font-size: 0.9rem;
                display: inline-block;
                vertical-align: text-bottom;
                margin-left: 0.5rem;
                padding: 0.3rem 0.3rem 0.4rem;
                line-height: 1;
                @extend .sb;
                background-color: $gris1;
                color: $blanc;
                border-radius: 0.35rem;
                &:hover{
                    background-color: $noir;
                }
            }
        }
    }
    #make_search {
        .type {
            #type_de_lunette {
                font-size: 1.2rem;
                @extend .font1,
                .sb;
                padding: 0.2rem 0.4rem;
                border: dashed 0.01rem rgba($noir, 1);

                option {}
            }
        }

        #make_search_type {
            #modele_pour {
                display: inline-block;
                vertical-align: middle;

                input {}

                label {}
            }
        }

        #modele_pour {
            label {
                margin-right: 0.1rem;
            }

            label+input {
                margin-left: 0.7rem;
            }
        }

        #rechercher, #rechercher_redirect {
            border: 0;
            cursor: pointer;
            font-size: .9rem;
            display: inline-block;
            vertical-align: middle;
            margin-left: 1rem;
            padding: .6rem .5rem .7rem;
            line-height: 1;
            background-color: $bleu1;
            color: $blanc;
            border-radius: .35rem;

            &:hover {
                background-color: $noir;
                color: $blanc
            }
        }
    }

    #make_search {
        display: block;
    }
    #search_results{
        display: none;
    }
    &.display_results {
        #make_search {
            display: none;
        }
        #search_results{
            display: block;
        }

    }
}



.designation_line {
    text-align: center;
    width: 100%;
    font-size: 1.4rem;
    text-transform: uppercase;
    @extend .sb;
    .icon_type{
        font-weight: 400;
        font-size: 2rem;
        display: inline-block;
        vertical-align: middle;
        margin-right: $gutter/2;
    }
    .pour, .total, .type{
        @extend .b;
        letter-spacing: 0.05rem;
        
    }
    .pour, .type{
        text-decoration-style: dotted;
        text-decoration-line: underline;
        text-decoration-thickness: 0.15rem;
    }
    .type{
        color: $gris1;
        text-decoration-color: $gris1;
    }
    .pour {}
    .total{
        color: $bleu1;
    }
}

#filters{
    position: fixed;
    bottom: 0;
    left: 0;    
    right: 0;
    z-index: 10;
    background-color: $gris2;
    #filters_inner{
        display: flex;
        align-items: center;
        justify-content: center;        
    }
}