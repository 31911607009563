#block_form {
    @extend .spacing_padding_block;
    max-width: 800px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: $gutter;
    padding-right: $gutter;
    margin-bottom: $gutter;
    font-size: 1.5rem;
    >* {
        margin-bottom: $gutter;
    }
    label{
        @extend .sb;
        display: inline-block;
        margin-bottom: 0.5rem;
    }
}
#form_top{
    .form_top_texte{
        @extend .b;
        font-size: 100%;
        .form_top_type{
            text-transform: uppercase;
            color: $blanc;
            display: inline-block;
            padding: 1rem;
        }
    }
    .form_top_titre {
        font-size: 130%;
        @extend .b;
        color: $bleu1;
    }
}

.form_block {
    .infos{
        @extend .b;
        color: $bleu1;
        font-size: 120%;
        text-transform: uppercase;
        line-height: 1.1;
    }
    .field_blocks{
        padding: $gutter / 2;
        padding-left: $gutter;
        border-left: 4px solid $bleu1;
        .field_block {
            width: 100%;
            &+.field_block {
                margin-top: $gutter;
            }           
        }

        .field_label {
            @extend .sb;
            display: block;
            margin-bottom: 6px;
            color: $bleu1;
            font-size: 80%;

        }
        .field_form{
            .checkboxes{
                label{
                    margin-left: 0.2rem;
                    margin-right: 0.5rem;
                }

            }
            input:not([type='checkbox']),
            textarea {
                font-size: 1em;
                display: block;
                @extend .font1;
                padding: 8px;
                border-color: $bleu1;
                width: 100%;

                outline: 0;
                border-width: 0;
                border-color: $bleu1;
                border-style: solid;
                border-left-width: 3px;
                border-bottom-width: 3px;
                transition: all 0.3s;

                &:focus {
                    border-left-width: 2px;
                    border-bottom-width: 2px;
                }
            
                &[type="file"] {
                                        
                }
                &[type='checkbox']{
                    
                }
                &[type='radio']{
                }
                
            }
            .radios{
                label{
                    //float: right;& + label {
                    & + input {
                        margin-left: 2rem;
                    }
                }
                input{
                    //float: left;
                    display: inline-block;
                    width: 20px;
                    
                }
            }
            select {
                @extend .font1;
                font-size: 1em;
                padding: 8px;
                min-width: 300px;
            }
        }


    }
}

input[type='checkbox'][name='photo_delete'] {
    position: relative;
    &:after {
        content: "cocher pour supprimer";
        position: absolute;
        left: 20px;
        width: 200px;
    }
}

.cck_group_x {
    width: 100%;
    .cck_form_group_x {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        justify-content: flex-end;
        &+.cck_form_group_x {
            margin-top: $gutter;
        }

        .horizontal_gx {
            .cck_form_group_x {
                .cck_cgx_button {}

                .cck_cgx_form {
                    width: 100%;
                    float: none;
                    display: block;
                    background: aliceblue;
                    padding: 2rem;
                    .cck_select_simple{
                        margin-bottom: $gutter/2;
                    }
                }
            }
        }
    }
}

.cck_field_x{
    .cck_form_field_x{
        float: none !important;
        .adminformlist{
            float: none;
            > div{
                margin-bottom: $gutter;
            }
        }
    }
}