.content_limit{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    &.w840{max-width: 840px;}
    &.w1200{max-width: 1200px;}
}

.spacing_padding_block{
    padding-top: $gutter*2;
    padding-bottom: $gutter*2;
    @media #{$md}{
        padding-top: $gutter*3;
        padding-bottom: $gutter*3;
    }
    @media #{$lg}{
        padding-top: $gutter*4;
        padding-bottom: $gutter*4;
    }
}
.spacing_margin_block{
    margin-top: $gutter*2;
    margin-bottom: $gutter*2;
    @media #{$md}{
        margin-top: $gutter*3;
        margin-bottom: $gutter*3;
    }
    @media #{$lg}{
        margin-top: $gutter*4;
        margin-bottom: $gutter*4;
    }
}

.padding_gutter {
    &.l{
        padding-left: $gutter / 4;
        @media #{$sm}{  padding-left: $gutter / 2;   }
        @media #{$md}{  padding-left: $gutter;  }
    }
    &.r{
        padding-right: $gutter / 4;
        @media #{$sm}{  padding-right: $gutter / 2;   }
        @media #{$md}{  padding-right: $gutter;  }
    }
    &.lr{
        padding-left: $gutter / 4;
        padding-right: $gutter / 4;
        @media #{$sm}{  padding-left: $gutter / 2;   padding-right: $gutter / 2;   }
        @media #{$md}{  padding-left: $gutter;       padding-right: $gutter;   }
    }
    &.b{
        padding-bottom: $gutter / 4;
        @media #{$sm}{  padding-bottom: $gutter / 2;   }
        @media #{$md}{  padding-bottom: $gutter;  }
    }
    &.t{
        padding-top: $gutter / 4;
        @media #{$sm}{  padding-top: $gutter / 2;   }
        @media #{$md}{  padding-top: $gutter;  }
    }
    &.bt{
        padding-bottom: $gutter / 4;
        padding-top: $gutter / 4;
        @media #{$sm}{  padding-bottom: $gutter / 2;   padding-top: $gutter / 2;   }
        @media #{$md}{  padding-bottom: $gutter;       padding-top: $gutter;   }
    }
    &.all{
       padding: $gutter / 4;
        @media #{$sm}{  padding: $gutter / 2;   }
        @media #{$md}{  padding: $gutter;  } 
    }
}

.margin_gutter {
    &.l{
        margin-left: $gutter / 4;
        @media #{$sm}{  margin-left: $gutter / 2;   }
        @media #{$md}{  margin-left: $gutter;  }
    }
    &.r{
        margin-right: $gutter / 4;
        @media #{$sm}{  margin-right: $gutter / 2;   }
        @media #{$md}{  margin-right: $gutter;  }
    }
    &.lr{
        margin-left: $gutter / 4;
        margin-right: $gutter / 4;
        @media #{$sm}{  margin-left: $gutter / 2;   margin-right: $gutter / 2;   }
        @media #{$md}{  margin-left: $gutter;       margin-right: $gutter;   }
    }
    &.b{
        margin-bottom: $gutter / 4;
        @media #{$sm}{  margin-bottom: $gutter / 2;   }
        @media #{$md}{  margin-bottom: $gutter;  }
    }
    &.t{
        margin-top: $gutter / 4;
        @media #{$sm}{  margin-top: $gutter / 2;   }
        @media #{$md}{  margin-top: $gutter;  }
    }
    &.bt{
        margin-bottom: $gutter / 4;
        margin-top: $gutter / 4;
        @media #{$sm}{  margin-bottom: $gutter / 2;   margin-top: $gutter / 2;   }
        @media #{$md}{  margin-bottom: $gutter;       margin-top: $gutter;   }
    }
    &.all{
       margin: $gutter / 4;
        @media #{$sm}{  margin: $gutter / 2;   }
        @media #{$md}{  margin: $gutter;  } 
    }
}



.items_margin {
    &.l{
        margin-left: $gutter / 8;
        @media #{$sm}{  margin-left: $gutter / 4;   }
        @media #{$md}{  margin-left: $gutter / 2;  }
    }
    &.r{
        margin-right: $gutter / 8;
        @media #{$sm}{  margin-right: $gutter / 4;   }
        @media #{$md}{  margin-right: $gutter / 2;  }
    }
    &.lr{
        margin-left: $gutter / 8;
        margin-right: $gutter / 8;
        @media #{$sm}{  margin-left: $gutter / 4;   margin-right: $gutter / 4;   }
        @media #{$md}{  margin-left: $gutter / 2;       margin-right: $gutter / 2;   }
    }
    &.all{
       margin: $gutter / 8;
        @media #{$sm}{  margin: $gutter / 4;   }
        @media #{$md}{  margin: $gutter / 2;  } 
    }
}


.fullAbsolute{
    position: absolute;top: 0;right: 0;bottom: 0;left: 0;
}

.fullFixed{
    position: fixed;top: 0;right: 0;bottom: 0;left: 0;
}

.bg-overlay{
    @extend .fullAbsolute;
    overflow: hidden;
}