body {
    position: relative;
    @extend .font1;

    overflow-x: hidden;
    overflow-y: scroll;
    @extend .scrollable, .vertical;

    >.login{
        margin-top: $gutter*2;
        text-align: center;
        .control-group:last-of-type{
            margin-top: $gutter;
        }

        &+div {
            margin-top: $gutter;
            margin-bottom: $gutter*2;
            text-align: center;
        }
    }
}

