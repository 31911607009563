$basculeWCarousel : $md;

.carousel{
    width: 100%;
    @media #{$basculeWCarousel} {
        height: 50vw;
        max-height: 80vh;
    }
    .carousel_wrapper{
        width: 100%;
        height: 100%;
        display: block;
        @media #{$basculeWCarousel} { display: flex; }

        .carousel_split {
            width: 100%;
            height: 100%;
            @media #{$basculeWCarousel} { width: 50%;}
            &.carousel_left{}
            &.carousel_right{}
            .carousel_split_inner {
                width: 100%;
                height: 100%;
                &.block_texte {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .description {
                        display: block;
                        font-size: 0.8rem;
                        padding: $gutter*2 $gutter;                        
                        @media #{$basculeWCarousel}{ font-size: 1.4vw; }
                        @media #{$xl}{ font-size: 1rem; }
                        span{
                            display: block;
                            line-height: 1;
                            @extend .sb;
                        }
                        &.carousel1_description{
                            span{
                                &.l1{font-size: 2em;}
                                &.l2{font-size: 5em; @extend .b; text-transform: uppercase;}
                                &.l3{font-size: 4.1em;}
                                &.l4{font-size: 7.6em; text-transform: uppercase;}
                            }
                        }
                        &.carousel2_description{
                            span{
                                &.l1{font-size: 2em;}
                                &.l2{font-size: 5em; @extend .b; text-transform: uppercase;}
                                &.l3{font-size: 3.7em;}
                                &.l4{font-size: 4.3em;}
                            }
                        }
                        .carouselLink{
                            margin-top: $gutter;
                            margin-left: $gutter/2;
                            display: inline-block;
                            background-color: $blanc;
                            color: $bleu1;
                            font-size: 1em;
                        }
                    }
                }
                &.block_image{
                    width: 100%;
                    height: 80vw;                    
                    @media #{$basculeWCarousel} {
                        height: 100%;
                    }
                    .carousel_bg{
                        width: 100%;
                        height: 100%;
                        background-size: cover;
                        background-position: 50% 60%;
                        transition: background-image .75s;
                    }
                }
            }
        }
    }
    &#carousel1{}
    &#carousel2{}
}