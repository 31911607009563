@font-face {
    font-family: "sudoptique";
    src: url("../fonts/sudoptique.eot");
    src: url("../fonts/sudoptique.eot?#iefix") format("embedded-opentype"),
         url("../fonts/sudoptique.woff") format("woff"),
         url("../fonts/sudoptique.ttf") format("truetype"),
         url("../fonts/sudoptique.svg#sudoptique") format("svg");
    font-weight: normal;
    font-style: normal;
}
 
[data-icon]:before,
  .cck_button_del > span:before,
  .cck_button_add > span:before,
  .cck_button_drag > span:before {
    font-family: "sudoptique" !important;
    content: attr(data-icon);
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before,
.cck_button_del > span:before,
.cck_button_add > span:before,
.cck_button_drag > span:before {
    vertical-align: middle;
    font-family: "sudoptique" !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-accessoires:before {
  content: "\61";
}
.icon-agenda:before {
  content: "\62";
}
.icon-contact:before {
  content: "\63";
}
.icon-filters:before {
  content: "\65";
}
.icon-lentilles:before {
  content: "\66";
}
.icon-map:before {
  content: "\67";
}
.icon-search:before {
  content: "\68";
}
.icon-soleil:before {
  content: "\69";
}
.icon-sport:before {
  content: "\6a";
}
.icon-vue:before {
  content: "\6b";
}
.icon-dossiers:before {
  content: "\64";
}
.icon-plus:before {
  content: "\6c";
}
.icon-minus:before {
  content: "\6d";
}
.icon-times:before {
  content: "\6e";
}
.icon-circle:before {
  content: "\6f";
}
.icon-facebook:before {
  content: "\70";
}
.icon-tw:before {
  content: "\71";
}
.icon-shopping-cart:before {
  content: "\72";
}
.icon-enfants:before {
  content: "\73";
}
.icon-femmes:before {
  content: "\74";
}
.icon-hommes:before {
  content: "\75";
}





[class^="icon-"],
[class*=" icon-"] {
    line-height: 1;
}

//sidebar
[icon-size="1"]{font-size: 1.1rem;}


///////////// SEBLOD

.cck_cgx.cck_cgx_button{
  width: 7rem;
  margin-left: 1rem;

  .cck_button
        {
    display: inline-block;
    vertical-align: middle;
    & + .cck_button{
      margin-left: $gutter/4;
    }

    &_del,
    &_add,
    &_drag {
      width: 1.5rem;
      height: 1.5rem;
      overflow: visible;
      > span {
        float: none;
        font-style: normal !important;
        font-weight: normal !important;
        font-variant: normal !important;
        text-transform: none !important;
        speak: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        vertical-align: middle;
        font-size: 1.5rem;
        width: 1.5rem;
        height: 1.5rem;
        line-height: 1;
        padding: 0;
        outline: 0;
      }
    }

    &_del > span {
      margin-right: 1rem;

      &:before {
        content: "\6d";
        color: red;
      }

    }

    &_add > span {
      margin-right: 1rem;

      &:before {
        content: "\6c";
        color: green;
      }

    }

    &_drag > span {
      &:before {
        content: "\6f";
        color: blue;
      }

    }
  }
}
.collection-group-button {
  padding: 0;
  .button-del,
  .button-add,
  .button-drag{
    & + div{
      margin-left: 1rem;
    }
  }
}