$navtop_width_bascule1 : "(min-width: 600px)";
$navtop_width_bascule2 : "(min-width: 1000px)";

#navTop {
    width: 100%;
    position: relative;
    z-index: 10;
    .navTop_inner{
        display: flex;
        justify-content: space-between;
        align-items: center;
        //height: 2*$gutter;
        padding: 0 $gutter/2;
        background-color: $blanc;
        box-shadow: $boxshadowTop;

        #logo{
            .logo_wrapper{
                svg{
                    display: block;
                    width: auto;
                    height: 2*$gutter;
                }
            }
        }

        #main_ico {     
            display: none;
            @media #{$navtop_width_bascule2}{
                display: flex;
            }
            .menuTop_item{
                border-bottom-width: 0.2rem;
                border-bottom-style: solid;
                border-bottom-color: transparent;
                &.menuTop_item{
                    margin-left: 1.5rem;
                }       
                &:hover, &.active{
                    border-bottom-color: $bleu1;
                }
                i{
                    display: block;
                    margin-bottom: 0.2rem;
                    font-size: 4rem;
                }
                &.parent{
                    position: relative;
                    .menuTop_child_items{
                        position: absolute;
                        visibility: hidden;
                        opacity: 0;
                        .menuTop_child_item{
                            display: flex;
                            align-items: center;
                            background-color: $blanc;
                            box-shadow: $box-shadow;
                            padding: $gutter/2;
                            text-align: left;
                            i{
                                margin-right: $gutter/2;
                            }
                            span{
                                @extend .sb;
                                width: 9rem;
                            }
                        }
                    }
                    &:hover{
                        .menuTop_child_items{
                            visibility: visible;
                            opacity: 1;
                            .menuTop_child_item:hover{
                                background-color: $noir;
                                color: $blanc;
                            }
                        }
                    }
                    &.active {
                        .menuTop_child_items {
                            .menuTop_child_item.active {
                                background-color: $bleu1;
                                color: $blanc;
                            }
                        }
                    }
                }
            }
        }
        #raccourcis {
            display: none;
            @media #{$navtop_width_bascule1} {
                display: flex;
            }
            color: $blanc;
            padding-bottom: 0.5rem;
            padding-top: 0.5rem;
            .menuTop_item{
                padding: 0.5rem 0;
                & + .menuTop_item{
                    margin-left: 1.4rem;
                }        
                .menuTop_item_wrapper{
                    display: block;
                    >div {
                        .surtexte {
                            @extend .b;
                        }
                        .soustexte {
                            display: none;
                        }
                    }
                    i{
                        display: block;
                        margin-bottom: 0.5rem;
                        font-size: 2.5rem;
                    }
                }
                &.item_contact{
                    color: $vert1;                    
                }
                &.item_map{
                    color: $orange1;                    
                }
                &.item_agenda{
                    color: $bleu1;                    
                }
            }
            @media #{$navtop_width_bascule2} {
                .menuTop_item {
                    width: $gutter*3;
                    &+.menuTop_item {
                        margin-left: 0.5rem;
                    }
                    .menuTop_item_wrapper{
                        >div {
                            .surtexte {
                                font-weight: 600;
                            }
                            .soustexte {
                                display: block;
                            }
                        }
                        i {
                            font-size: 1.6rem;
                        }
                    }
                    &.item_contact{
                        background-color: $vert1;
                        color: $blanc;
                    }
                    &.item_map{
                        background-color: $orange1;
                        color: $blanc;
                    }
                    &.item_agenda{
                        background-color: $bleu1;
                        color: $blanc;
                    }
                }
            }
        }

        .menuTop_item{
            padding-bottom: 0.5rem;
            text-align: center;
            display: block;
            &.menuTop_item{
                margin-left: 0.5rem;
            }
            .menuTop_item_wrapper{
                i{}
                div{
                    span{
                        display: block;
                        &.surtexte{
                            text-transform: uppercase;
                            @extend .sb;
                            font-size: 0.9rem;
                            line-height: 1;
                        }
                        &.soustexte{
                            font-size: 0.8rem;
                        }
                    }
                }
            }
        }

        #menu_ico {
            text-align: center;
            display: block;
            @media #{$navtop_width_bascule2}{
                display: none;
            }
            #menu_icon {
                //padding-bottom: $gutter/2;
                margin-bottom: .5rem;
                cursor: pointer;

                .bar {
                    transform: translateY(0rem) rotate(0deg);
                    display: block;
                    height: 0.25rem;
                    width: $gutter;
                    background-color: $bleu1;

                    &:nth-child(2) {
                        width: $gutter - 0.3rem;
                        margin-left: 0.3rem;
                    }

                    &+.bar {
                        margin-top: 0.4rem;
                    }
                }
            }
        }

    }
}