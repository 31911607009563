.form_wrapper {
    z-index: 1000;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    @media #{$md} {
        //justify-content: center;
    }

    background:rgba($bleu1, 0.95);
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

    transition:all 0.3s;

    &.hide_form {
        opacity: 0;
        transform: translateX(200%);
        overflow-y: hidden;
    }

    > form{
        display: none;
    }

}

body{
    overflow-y: auto;
    &[data-mail-opened="false"] {
        overflow-y: auto;
        .form_wrapper {
            opacity: 0;
            transform: translateX(200%);
            overflow-y: hidden;
        }
    }

    &[data-mail-opened="true"] {
        overflow-y: hidden;
        .form_wrapper {
            transform: translateX(0%);
            opacity: 1;
            overflow-y: auto;
        }
    }
}

.form {
    margin-bottom: $gutter * 2;
    background: $blanc;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.4);
    color: $noir;
    width: 90%;
    max-width: 600px;
    position: relative;
    margin-top: $menu_height * 2;
    ::placeholder {
        //font-weight: 200;
        opacity: 0.8;
        font-size: 90%;
    }

    padding: $gutter $gutter*1.5 $gutter/1.5;

    @media #{$md} {
        padding: $gutter*1.5 $gutter*2;
        //width: 400px;
    }

    .form_inner {
        max-width: 100%;
    }

    .fermer_form {
        position: absolute;
        top: $gutter/4;
        right: $gutter/2;
        font-size: 2.5em;
        z-index: 1000;
        font-family: arial;
    }

    .form_title {
        h3 {
            display: block;
            color: #17b4df;
            font-size: 200%;
            line-height: 1;
            margin-bottom: 2rem;
            @extend .b;
        }

        span {
            font-size: 100%;
            margin-bottom: 2rem;
            display: block;
        }
    }

    label {
        //text-transform: uppercase;
        @extend .b;
        color: $bleu1;
        font-size: 120%;
        letter-spacing: .1em;
        display: block;
    }

    input,
    textarea {
        font-size: 1.2em;
        letter-spacing: 1px;
        width: 80%;
        margin: 0.5em 0 1.6em;
        padding-left: 0.5em;
        padding-bottom: 0.7em;
        outline: 0;
        border-width: 0;
        border-color: $bleu1;
        border-style: solid;
        border-left-width: 2px;
        border-bottom-width: 2px;
        border-bottom-left-radius: 10px;
        transition: all 0.3s;

        &:focus {
            border-left-width: 1px;
            border-bottom-width: 1px;
        }
    }

    textarea {
        resize: none;
        line-height: 1.5;
    }

    p {
        width: 100%;
        font-size: 2em;
    }

    .tel {
        margin-top: 0.5em;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 2.8em;

        svg {
            height: 1em;
            width: auto;
        }

        .mobileTel {
            display: block;

            @media #{$md} {
                display: none;
            }
        }

        .pcTel {
            display: none;

            @media #{$md} {
                display: block;
            }
        }
    }

    .honey {
        display: none;
    }

}

.item_shop{
    margin-bottom: $gutter;
    .item_title{
        margin-bottom: $gutter;
        display: block;
    }
    .item_pix{
        width: 70%;
        margin-left: auto;
        margin-right: auto;
        display: block;
        box-shadow: 0 0.5rem 1rem 0rem rgba($noir, 0.15);
    }
}

.form_foot{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .btn{}
    .info{
        margin-left: 1rem;
        line-height: 1;
        font-size: 1.1rem;
    }
}