$fwglasses_anim_duration: 3s;
$fwglasses_anim_delay: 0.5s;
$fwlogo_wrapper_duration: 0.5s;
$fwglasses_duration: 0.75s;

#fwglasses{

    display: flex;
//display: none;

    z-index: 1000;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    //@extend .spacing_padding_block;
    opacity: 1;
    visibility: visible;
    animation: fwglasses $fwglasses_duration ease-out (($fwglasses_anim_duration + $fwglasses_anim_delay + $fwlogo_wrapper_duration) - 0.85s) forwards;
    #fwglasses_bg_blanc {
        @extend .fullAbsolute;
        background-color: $blanc;
    }

    #fwlogo_wrapper {
        @extend .fullAbsolute;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: rotateX(0deg);
        animation: fwlogo_wrapper $fwlogo_wrapper_duration ease-in (($fwglasses_anim_duration + $fwglasses_anim_delay) - 1s) forwards;
        #fw_logo {
            width: 80%;
            height: 80%;
            filter: blur(10px);
            animation: fwglasses_logo $fwglasses_anim_duration ease-out $fwglasses_anim_delay forwards;
            svg{
                width: 100%;
                height: 100%;
            }
        }
    }
    #fwglasses_bg{
        @extend .fullAbsolute;
        background-size: 120% auto;
        @media #{$md}{
            background-size: cover;
        }
        background-repeat: no-repeat;
        background-position: 50% 40%;
        transform-origin: center 30%;
        animation: fwglasses_bg $fwglasses_anim_duration ease-in-out $fwglasses_anim_delay forwards;
    }
}

@keyframes fwglasses_bg{
    0%{     visibility: visible;    opacity: 1; transform: scale(1);}
    10%{    visibility: visible;    opacity: 1; transform: scale(1);}
    98%{    visibility: visible;    opacity: 1; transform: scale(25);}
    100%{   visibility: hidden;     opacity: 0; transform: scale(25);}
}
@keyframes fwglasses_logo {
    0%{filter: blur(10px)}
    60%{filter: blur(0px)}
    100%{filter: blur(0px)}
}
@keyframes fwlogo_wrapper {
    0% {transform: rotateX(0deg);}
    100% {transform: rotateX(90deg);}
}
@keyframes fwglasses {
    0% {opacity: 1;visibility: visible;}
    100% {opacity: 0;visibility: hidden;}
}