// Colours

$bleu1:#2AA7DF;
$vert1:#6f9e1e;
$orange1:#ff8f1e;
$gris1:#c8c8c8;
$gris2:#f5f5f5;

$vue: #efc8b8;
$soleil: #dad0b0;
$sport: #bcbcbc;

$homme: #579aff;
$femme: #fe7aa7;
$enfant: #f7ac3b;

$blanc: #FEFEFE;
$noir: #4d4d4d;

$caddie: #005ABC;
$fb: #3B5998;
$tw: #1DA1F2;





@include collection_color(noir, $noir);
@include collection_color(blanc, $blanc);

@include collection_color(bleu1, $bleu1);
@include collection_color(vert1, $vert1);
@include collection_color(orange1, $orange1);
@include collection_color(gris1, $gris1);

@include collection_color(homme, $homme);
@include collection_color(femme, $femme);
@include collection_color(enfant, $enfant);

@include collection_color(vue, $vue);
@include collection_color(soleil, $soleil);
@include collection_color(sport, $sport);



// Gradiants
//$bg-gradiant-wrapper: linear-gradient(-30deg, #17181a, #030308);
//$bg-gradiant-header-lr: linear-gradient(145deg, rgba($blanc, 0.15) 0%, rgba($gris_sombre, 0) 50%);
//$bg-gradiant-header-rl: linear-gradient(-145deg, rgba($blanc, 0.15) 0%, rgba($gris_sombre, 0) 50%);
//$bg-gradiant-header2: radial-gradient(ellipse at center, rgba($blanc, 0.2) 0%, rgba($noir, 0) 50%);
//$bg-gradiant-header: radial-gradient(ellipse at center, rgba($blanc, 0.15) 0%, rgba($gris_sombre, 0) 50%);

//@include gradient($angle, $bleu1, $bleu2);

