.block{
    background-color: $gris2;
    .block_wrapper{
        text-align: center;
        max-width: 1600px;
        margin-left: auto;
        margin-right: auto;
        @extend .spacing_padding_block;
        padding-left: $gutter;
        padding-right: $gutter;

        .block_texte{
            margin-left: auto;
            margin-right: auto;
            .top_titre{
                @extend .sb;
                text-transform: uppercase;
                margin-bottom: $gutter;
            }
            .title{
                @extend .b;
                margin-bottom: $gutter;
            }
            .texte{
                margin-bottom: $gutter*2;
            }
        }

        .block_lunettes{
            display: flex;
            justify-content: center;
            align-items: flex-end;
            font-size: 1vw;
            .block_lunette{
                position: relative;
                & + .block_lunette{
                    margin-left: $gutter;
                }
                .lunettes_type_img {
                    display: block;
                    width: 100%;
                    transform: scale(1.2) translateY(-20%);
                    transform-origin: bottom center;
                    transition: transform 0.15s;
                }
                .block_lunette_texte {
                    display: block;
                    padding: 0 $gutter/2 $gutter $gutter/2;
                    font-size: 4em;
                    text-align: left;
                    @extend .sb;
                    text-transform: uppercase;
                    line-height: 1;
                    .type_lunette{
                        line-height: 1;
                        color: $blanc;
                        &.type_lunette_type{
                            color: $noir;
                            @extend .b;
                        }
                    }
                }

                &:hover{
                    .lunettes_type_img {
                        transform: scale(1.3) translateY(-25%);
                    }
                }

            }
        }
    }
}


.photos{

    margin-top: 0.5rem;

    display: grid;
    grid-gap: 15px;
    place-content: center;

    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(8, 1fr);

    .imgs1:nth-of-type(1) {
            grid-column: span 2;
            grid-row: span 2;
        }
    @media #{$md}{
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(5, 1fr);

        .imgs1:nth-of-type(7),.imgs1:nth-of-type(13) {
            grid-column: span 2;
            grid-row: span 2;
        }

    }
    @media #{$xl}{
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(4, 1fr);
    }

    img{
        width: 100%;
        height: auto;
        display: block;
    }
}

.ggMap {
    overflow: hidden;
    background-color: $caddie;

    .title{
        color: $blanc;
    }

    .module_map_wrapper {

        .module_map {
            overflow: visible !important;
            height: 100%;
            width: 100%;
        }
    }
}

.sitemap {
    background-color: $bleu1;

    .title,
    .menu_ico {
        color: $blanc;
    }
    
    .menu_ico{
        display: block;
        text-align: left;
        font-size: 120%;
        & + .menu_ico{
            margin-top: $gutter/2;
        }
        i {
            margin-right: $gutter/2;
            font-size: 200%;
        }
        span{
            @extend .b;            
        }
    }

}

.gm-style-iw div div {
    @extend .font1;
}

#sitemap_footer{
    .block_wrapper{
        .menu_footer{
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            @media #{$md} {
                flex-direction: row;
                align-items: flex-start;
                justify-content: space-around;
            }
            .menu_split{
                //width: 50%;
            }
            .menu_left{}
            .menu_right {
                margin-top: $gutter/2;
                @media #{$md} {
                    margin-top: 0;
                }
            }
        }
    }
}