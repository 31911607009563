$basculeW: $md;

#content {
    @extend .spacing_padding_block, .content_limit,.w840;
    padding-left: $gutter;
    padding-right: $gutter;
    #content_inner{
        #content_top{
            margin-bottom: $gutter;
        }
    }

    #content_middle{
        margin-bottom: $gutter;
        .item_middle_inner{
            @media #{$basculeW} {
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
            }
            #item_pictures{
                width: 100%;
                #item_main_picture {
                    &.item_main_picture_lunettes{
                        height: 0;
                        padding-bottom: 60%;
                        overflow: hidden;
                    }
                    @extend .box-shadow-items;
                    img{
                        display: block;
                        width: 100%;
                        height: auto;
                    }
                }
                #item_additional_picture{
                    display: flex;
                    align-items: center;
                    justify-content: center;  
                    flex-wrap: wrap;
                    .item_additional_picture {
                        margin-top: -1vw;
                        cursor: pointer;
                        @extend .box-shadow-items;
                        //height: 0;
                        //padding-bottom: 9.6%;
                        $addImgWidth: 100px;
                        $addImgHeightRatio: 0.6;
                        width: 60px;
                        height: $addImgHeightRatio;
                        @media #{$sm}{
                            $addImgWidth: 80px;
                            width: $addImgWidth;
                            height: $addImgWidth * $addImgHeightRatio;
                        }
                        @media #{$md}{
                            $addImgWidth: 110px;
                            width: $addImgWidth;
                            height: $addImgWidth * $addImgHeightRatio;
                        }
                        @media #{$lg}{
                            $addImgWidth: 110px;
                            width: $addImgWidth;
                            height: $addImgWidth * $addImgHeightRatio;
                        }
                        overflow: hidden;
                        img{
                            width: 100%;
                            height: auto;
                        }
                        & + .item_additional_picture{
                            margin-left: 0.6rem;
                        }
                        &.active{
                            border: 3px solid $bleu1;
                        }
                    }
                }
            }

            #item_side_infos{
                margin-top: $gutter;
                @media #{$basculeW} {
                    margin-top: 0;
                    margin-left: $gutter;
                }
                #item_side_infos_inner{
                    display: flex;
                    flex-direction: row;   
                    justify-content: center; 
                    align-items: center; 
                    @media #{$basculeW} {
                        flex-direction: column;     
                    }
                    .side_info_item {
                        color: $blanc;                          
                        width: $gutter*2;
                        height: $gutter*2;
                        display: flex;
                        align-items: center;    
                        justify-content: center;
                        font-size: 1.5rem;
                        & + .side_info_item{
                            margin-left: 0.5rem;
                            @media #{$basculeW} {
                                margin-top: 0.5rem;
                                margin-left: 0;
                            }
                        }
                        &.prix{ background-color: $noir; font-size: 110%;line-height: 1;text-align: center;}
                        &.caddie{ background-color: $caddie;}
                        &.fb{ background-color: $fb;}
                        &.tw{ background-color: $tw;}
                        > i, > span{

                        }
                    }
                }
            }
        }
    }

    #item_bottom {
        margin-bottom: $gutter*2;
        padding-top: $gutter;
        .item_details_inner {
            border-left: 4px solid $gris1;
            padding-left: $gutter;
            .item_detail{
                text-transform: uppercase;
                font-size: 1.4rem;
                @extend .b;
                .item_detail_label{
                    color: $gris1;
                }
                .item_detail_value{}
            }
        }
    }

    #item_bottom_last{
        .item_description_inner{
            background-color: $gris2;
            padding: $gutter;
            //@extend .sb;
            .description{}
        }
    }
}

#related{
    background-color: $gris2;
    position: relative;
    z-index: 0;
    @extend .spacing_padding_block;
    #related_inner{
        
        .related_title{
            margin-bottom: $gutter;
            text-align: center;
            width: 90%;
            margin-left: auto;
            margin-right: auto;
            @extend .b;
            font-size: 130%;
            text-transform: uppercase;
            span{}
        }
    }
}

.item_designation{
    text-align: left;
    .item_ico {
        background-color: $gris1;
        color: $blanc;
        display: inline-block;
        vertical-align: middle;
        margin-right: $gutter/3;
        i {
            width: $gutter;
            height: $gutter;
            font-size: 1.6rem;
            line-height: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: $gutter;
        }
    }
    .texte_pour{
        color: $gris1;
    }
    .type{}
    .item_titre{
        @extend .b;
    }
}