#main_menu_container{
    //display: none;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $bleu1;
    overflow-y: auto;
    @extend .scrollable.vertical;
    opacity: 0;
    visibility: hidden;
    #main_menu_inner{
        margin-top: $gutter;
        margin-bottom: $gutter;
        position: relative;
        width: 400px;
        margin-left: auto;
        margin-right: auto;
        #main_menu_wrapper {

            .logo_menu_wrapper{
                margin-bottom: $gutter;
                display: block;
                #logo_g{
                    #textes{
                        fill: $blanc;
                    }
                }
            }
            
            .nav{
                color: $blanc;
                ul{}
                li{}
                a {
                    @extend .sb;
                }
                > li{
                    margin-bottom: $gutter/2;
                    &.active{
                        > a{
                            padding-left: 0.5rem;
                            padding-right: 0.5rem;
                            display: inline-block;
                            background-color: $blanc;
                            color: $bleu1;
                        }
                    }
                    >a {
                        font-size: 130%;
                        @extend .b;
                        margin-bottom: $gutter/2;
                        display: block;
                    }
                    >ul {
                        padding-left: $gutter/2;
                        >li {
                            margin-bottom: $gutter/4;
                            &.active {
                                >a {
                                    padding-left: 0.5rem;
                                    padding-right: 0.5rem;
                                    display: inline-block;
                                    background-color: $blanc;
                                    color: $bleu1;
                                }
                            }
                            >a {
                                font-size: 100%;
                                margin-bottom: $gutter/2;
                                display: block;
                                border-left: 1px solid #fff;
                                padding-left: 1rem;
                            }
                            >ul {
                                padding-left: $gutter;
                                >li {
                                    margin-bottom: $gutter/8;
                                    border-left: 1px solid #fff;
                                    padding-left: $gutter/2;
                                    &.active {
                                        >a {
                                            padding-left: 0.5rem;
                                            padding-right: 0.5rem;
                                            display: inline-block;
                                            background-color: $blanc;
                                            color: $bleu1;
                                        }
                                    }
                                    a {
                                        font-size: 100%;

                                    }
                                }
                            }

                        }
                    }
                }
            }



        }
    }
}

.menu_titre{
    span{}
    margin-bottom: $gutter/2;
}

