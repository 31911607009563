.item_list_wrapper{
    display: flex;
    //justify-content: center;
    flex-wrap: wrap;
    padding-top: $gutter;
    width: 100%;
    max-width: 1600px;
    margin-left: auto;
    margin: auto;
}

$topLeftGutt : $gutter*1;
.intro{
    &.item{
        width: 96%;
        margin-left: 2%;
        margin-right: 2%;

        @media #{$sm} {
            width: 46%;
            margin-left: 2%;
            margin-right: 2%;
        }
        @media #{$md} {
            width: 29.33%;
            margin-left: 2%;
            margin-right: 2%;
        }
        /* @media #{$xxl} {
            width: 21%;
            margin-left: 2%;
            margin-right: 2%;
        } */
        margin-bottom: $gutter*2;
        .item_inner{
            position: relative;
            padding-top: $topLeftGutt / 2;
            padding-left: $topLeftGutt / 2;
            width: 100%;
            .item_abs{
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1;
                span{
                    position: absolute;
                    top: 0;
                    width: $topLeftGutt;
                    height: $topLeftGutt;
                    display: block;
                    border-radius: 50%;
                }
                span:nth-child(1){z-index: -1;left: 0;}
                span:nth-child(2){z-index: -2;left: $topLeftGutt/2;}
                span:nth-child(3){z-index: -3;left: $topLeftGutt;}
            }

            .item_marque{
                position: absolute;
                top: -0.8rem;
                right: 0;
                text-align: right;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                white-space: pre;
                .marque, .prix{
                    @extend .sb;
                    font-size: 0.9rem;
                    line-height: 1;
                    text-transform: uppercase;
                    display: inline-block;
                    vertical-align: top;                
                }
                .marque {
                    color: $gris1;
                }
                .prix {
                    color: $noir;
                }
            }

            .item_content{
                width: 100%;
                display: block;

                .item_photo{
                    margin-bottom: $gutter/2;
                    img{
                        display: block;
                        width: 100%;
                        max-width: 100%;
                        height: auto;
                    }
                }

                .item_details{
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    .item_ico{
                        background-color: $noir;
                        color: $blanc;
                        width: $gutter;
                        height: $gutter;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 1.6rem;
                        margin-right: $gutter/3;
                        i{}
                    }
                    .item_titre{
                        @extend .sb;
                        font-size: 1.4rem;
                        line-height: 1.1;
                        text-transform: capitalize;
                    }
                }
            }
        }
        &.lunettes{
            .item_inner {
                .item_photo {
                    @extend .box-shadow-items;
                }
            }
        }
        &.lentilles, &.accessoires {
            .item_inner {
                .item_photo {
                    //border-left: 3px dotted $bleu1;
                    //border-right: 1px dotted $noir;
                    position: relative;

                    .more{
                        position: absolute;
                        bottom: 0.3rem;
                        right: -0.3rem;
                        text-align: right;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;

                        >* {
                            @extend .box-shadow-items;
                            display: inline-block;
                            background-color: $bleu1;
                            color: $blanc;
                            padding: 0.1rem 0.5rem;
                            border-radius: 0.25rem;
                            opacity: 0.9;
                            @extend .sb;
                            & + * {
                                margin-top: 0.3rem;
                            }
                        }
                    }
                }
                .item_details {
                    .item_ico {
                        background-color: $bleu1;
                        &.lentille{
                            background-color: $bleu1;
                        }
                        &.accessoire{
                            background-color: $vert1;
                        }
                    }
                }
            }
        }

        &.accessoires {
            .item_inner {
                .item_photo {
                    @extend .box-shadow-items;
                }

                .item_details {
                    .item_ico {
                        background-color: $vert1;
                    }
                }
            }
        }
    }
}

.item_admin{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 0.5rem;
    .cck_button_free+.cck_button_free{
        margin-left: 1rem;
    }
}